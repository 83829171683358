import React from "react"
import SchedulePage from ".."
import ScheduleHeader from "../Header"
import OurService from "../OurService"
import ModuleTitle from "../../../atoms/SchedulePages/ModuleTitle"
import { PickupLocations, PickupLocation } from "../Locations"
import { Schedule, ScheduleTable } from "../Schedule"
import Search from "../../HomePage/Search"

import Pickup_Locations from "./pickup-locations"
import { FortCollinsMeta, FortCollinsService } from "./metadata"
import VisitingFortCollins from "./Visiting"
import FortCollinsData from "./schedule"


const FortCollinsSchedule = ({ history }) => {
  return (
    <SchedulePage>
      <div className="schedule-search-form">
        <Search history={history} remoteCallSearchComponent={true} />
      </div>
      <div className="schedule-page">
        <ScheduleHeader metadata={FortCollinsMeta.header} />

        <p className="special-notice">Due to the construction at FNL Airport, parking is limited.<br/>
        Please plan accordingly and allow extra time to navigate construction and find parking, or check out our FREE customer parking lot at our Mulberry (MUL) location.</p>

        <ModuleTitle title="Schedules" />
        <Schedule>
          <ScheduleTable schedule={FortCollinsData.to} />
          <ScheduleTable schedule={FortCollinsData.from} />
        </Schedule>

        <PickupLocations>
          {Pickup_Locations.map(location => {
            return <PickupLocation location={location} />
          })}
        </PickupLocations>

        <OurService service={FortCollinsService} />
        <VisitingFortCollins />

      </div>
    </SchedulePage>
  )
}

export default FortCollinsSchedule