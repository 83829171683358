import React from "react"

const FortCollinsMeta =  {
    header: {
        title: "Fort Collins Airport Shuttle",
        subtitle: "Fort Collins | Loveland | Timnath | Wellington | Windsor",
        description: "Please note check-in closes 15 minutes before the time of departure and Landlines depart promptly at your scheduled time.\nPlease arrive early and have your ID ready to ensure an on-time departure."
    }
    
}

const FortCollinsService = <>
    <p>Let Landline’s premium airport shuttle service between Fort Collins/ Loveland and Denver International Airport (DEN/DIA) be the best part of your travel day.</p>
    <p>Avoid the stress and expense of driving, parking or ridesharing and instead opt for Landline’s comfortable, Wi-Fi enabled vehicles.</p>
    <p>Enjoy modern conveniences like air conditioning, in seat power, reclining leather seats, and get caught up on work or stream your favorite shows while you ride.</p>
    <p>Our friendly staff will help you with loading and unloading your luggage on both ends of your trip ensuring a smooth and efficient trip.</p>
    <p>Landline is ideal for families, college students, leisure and business travelers alike looking for a safe, reliable and an affordable way to/ from DIA.</p>
</>

export {
    FortCollinsMeta, FortCollinsService
}