import "./PaymentPage.scss"

import { NotificationConsumer } from "ibe-components"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, withRouter } from "react-router-dom"
import { Elements, StripeProvider } from "react-stripe-elements"
import styled from "styled-components"

import { BUY_MILES, PAYMENT } from "../../../constants/routes"
import cartActions from "../../../redux/cart/actions"
import errorActions from "../../../redux/error/actions"
import instructionActions from "../../../redux/instruction/actions"
import itineraryActions from "../../../redux/itinerary/actions"
import redirectActions from "../../../redux/redirect/actions"
import {
  accountSelector,
  cartSelector,
  errorSelector,
  instructionSelector,
  itinerarySelector,
  loyaltySelector,
  passengerSelector,
  reservationsSelector,
  settingsSelector,
  ticketSelector,
  tripSelector,
} from "../../../redux/selector"
import settingsActions from "../../../redux/settings/actions"
import tripActions from "../../../redux/trip/actions"
import { allCardProductsEnums, getCartItemUtil, paymentTypeEnums } from "../../../utils/cart-util"
import { isEmptyObject, reduceErrors } from "../../../utils/common"
import { getRouteIndex } from "../../../utils/tripUtil-util"
import { validateConnectionGarantie, validateDoor2Door } from "../../../utils/validation"
import Breadcrumbs from "../../atoms/Breadcrumbs"
import ErrorBoundary from "../../atoms/ErrorBoundary"
import Loading from "../../atoms/Loading"
import LoyaltyReward from "../../atoms/LoyaltyReward"
import PageLayout from "../../layouts/PageLayout"
import TripSummary from "../../molecules/TripSummary"
import ConnectionGarantie from "../../organisms/ConnectionGarantie"
import CreditCardForm from "../../organisms/CreditCardForm"
import Door2Door from "../../organisms/Door2Door"
import PaymentPrices from "../../organisms/PaymentPrices"
import MobileBreadcrumbs from "../../atoms/Breadcrumbs/MobileBreadcrumbs"

const ReactMarkdown = require("react-markdown")

const Payment = ({ history }) => {
  const dispatch = useDispatch()
  const [showPaymentCapture, setShowPaymentCapture] = useState(false)
  const checkoutCart = useCallback(
    (stripeToken, cardHolderName) =>
      dispatch(cartActions.checkoutCart({ stripeToken, cardHolderName, history })),
    [dispatch],
  )
  const settings = useSelector(state => settingsSelector(state))
  const trip = useSelector(state => tripSelector(state))
  const { editTrip } = trip
  const setRedirectURL = useCallback(url => dispatch(redirectActions.setRedirectURL(url)), [
    dispatch,
  ])
  const getAllCartProducts = useCallback(() => dispatch(cartActions.getAllCartProducts())[dispatch])
  const addCartItem = useCallback(item => dispatch(cartActions.addCartItem(item)), [dispatch])
  const removeCartItem = useCallback(item => dispatch(cartActions.removeCartItem(item)), [dispatch])
  const getCart = useCallback(uuid => dispatch(cartActions.getCart(uuid)))
  const getStripePublishableKey = useCallback(() =>
    dispatch(settingsActions.getSettingsData(), [dispatch]),
  )
  const fetchItinerary = useCallback(() => dispatch(itineraryActions.fetchItinerary()), [dispatch])
  const clearError = useCallback(() => dispatch(errorActions.clearError()), [dispatch])
  const fetchDoor2DoorService = useCallback(
    res => dispatch(instructionActions.fetchDoor2DoorService(res)),
    [dispatch],
  )
  const instruction = useSelector(state => instructionSelector(state))

  const itinerary = useSelector(state => itinerarySelector(state))
  const passenger = useSelector(state => passengerSelector(state))
  const cart = useSelector(state => cartSelector(state))

  const {
    departureTicket,
    returnTicket,
    departureTicketLayoverTimes,
    returnTicketLayoverTimes,
    departureTicketTotalTripTime,
    returnTicketTotalTripTime,
  } = useSelector(state => ticketSelector(state))

  const { params: bookingSearchParams } = useSelector(state => reservationsSelector(state))
  const error = useSelector(state => errorSelector(state))
  const loyalty = useSelector(state => loyaltySelector(state))
  const walletBalance = (loyalty.wallet || {}).points
  const isLoyaltyActive = loyalty.isActive

  const { isAuthenticated } = useSelector(state => accountSelector(state))

  const [state, setState] = useState({
    rememberCCInformation: false,
    cardHolderName: "",
    voucherCode: "",
    loading: false,
    formErrors: {},
    modalErrors: {},
    stripeErrors: null,
    showBuyPointsPage: false,
  })

  const [showPayButton, setShowPayButton] = useState(true)

  useEffect(() => {
    getStripePublishableKey()
    getAllCartProducts()
    getCart(cart.uuid)
    fetchDoor2DoorService({
      departure: departureTicket[0].departure_code,
      arrival: departureTicket[departureTicket.length - 1].arrival_code,
    })
    if (trip.flight_seat_info && !trip.flight_seat_info.ticket_id) {
      fetchItinerary()
    }
  }, [])

  const routeIndex = getRouteIndex(PAYMENT)

  useEffect(() => {
    if (trip.bookingFlowIndex !== routeIndex) {
      dispatch(tripActions.setBookingFlowIndex(routeIndex))
    }
  }, [routeIndex])

  const changeState = params => setState(prevState => ({ ...prevState, ...params }))

  const onCreditCardSubmitted = async (e, stripe) => {
    e.preventDefault()
    setShowPayButton(false)
    if (!cart.is_payment_necessary) {
      if (walletBalance < cart.summary_lp) {
        setShowPayButton(true)
        setRedirectURL(PAYMENT)
        changeState({ showBuyPointsPage: true })
      } else {
        checkoutCart()
      }
    } else {
      const { error: stripeError, token } = await stripe.createToken({ type: "card" })
      if (stripeError) {
        setShowPayButton(true)
        changeState({ stripeErrors: stripeError.payment_failed || stripeError.message })
      } else if (token) {
        checkoutCart(token.id, state.cardHolderName)
      }
    }
  }

  const onAddPromoCodeClicked = () => {
    if (!state.voucherCode) {
      return
    }
    const cartItem = getCartItemUtil(allCardProductsEnums.DISCOUNT, paymentTypeEnums.REGULAR, 1, {
      vouchers_id: state.voucherCode,
    })
    addCartItem(cartItem)
  }

  const voucherItem = cart.items_list.find(
    ({ product }) => product.category === allCardProductsEnums.DISCOUNT,
  )

  const onRemovePromoCodeClicked = () => {
    if (voucherItem) {
      removeCartItem(voucherItem.id)
    }
    changeState({ voucherCode: null })
  }
  const handlePromoCodeChange = voucherCode => {
    changeState({ voucherCode })
  }

  const door2DoorValidation = values => {
    const errors = validateDoor2Door(values)

    if (errors && Object.keys(errors).length > 0) {
      changeState({
        formErrors: errors,
        modalErrors: reduceErrors(errors),
      })
      return false
    }
    changeState({
      formErrors: {},
      modalErrors: {},
    })
    return true
  }

  const connectionGarantieValidation = values => {
    const errors = validateConnectionGarantie(values)

    if (errors && Object.keys(errors).length > 0) {
      changeState({
        formErrors: errors,
        modalErrors: reduceErrors(errors),
      })
      return false
    }
    changeState({
      formErrors: {},
      modalErrors: {},
    })
    return true
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [showPaymentCapture])

  return (
    <>
      <Helmet>
        <script>
          {(function sojernPixel() {
            const params = {
              vd1: departureTicket[0].departure_date,
              vd2: returnTicket ? returnTicket[0].departure_date : " ",
              vconfno: cart.reservation?.rloc,
              vcu: `${returnTicket ? returnTicket[0].tickets[0].currency : " "}`,
              vp: cart.summary,

              vf1: departureTicket[0].departure,
              vs1: "",
              vn1: "",
              vb: "",
              t: itinerary.payload.passengers.length,
              sha256_eml: "",
              sha1_eml: "",
              md5_eml: "",
              ccid: "",
            }
            /* Please do not modify the below code. */ const cid = []
            const paramsArr = []
            const cidParams = []
            const pl = document.createElement("script")
            const defaultParams = { vid: "tou", et: "vc" }
            Object.entries(defaultParams).forEach(key => {
              params[key] = defaultParams[key]
            })
            Object.entries(cidParams).forEach(key => {
              cid.push(params[cidParams[key]])
            })
            params.cid = cid.join("|")
            Object.entries(params).forEach(key => {
              paramsArr.push(params[params[key]])
            })

            pl.type = "text/javascript"
            pl.async = true
            pl.src = `https://beacon.sojern.com/pixel/p/321524?f_v=v6_js&p_v=1&${paramsArr.join(
              "&",
            )}`
            ;(
              document.getElementsByTagName("head")[0] || document.getElementsByTagName("body")[0]
            ).appendChild(pl)
          })()}
        </script>
      </Helmet>
      <NotificationConsumer>
        {({ actions }) => {
          return (
            <ErrorBoundary>
              <PageLayout
                background={"#fff"}
                contentStyle={{
                  alignContent: "flex-start",
                }}
                render={() => {
                  if (!passenger[0] && trip.flight_seat_info && !trip.flight_seat_info.ticket_id) {
                    return <Redirect to="/" />
                  }
                  if (state.showBuyPointsPage) {
                    actions.show({
                      type: "is-danger",
                      message:
                        "You do not have enough points to complete this transaction - please buy miles",
                      miliseconds: 5000,
                    })
                    return <Redirect to={BUY_MILES} />
                  }
                  if (itinerary.isBusy || cart.isBusy || (error && error.isBusy)) {
                    return <Loading />
                  }
                  if (state.stripeErrors) {
                    actions.show({ type: "is-danger", message: state.stripeErrors })
                    setState(prevState => ({ ...prevState, stripeErrors: null }))
                    return false
                  }
                  if (error) {
                    const errorField = error.code ? error.code : error //
                    actions.show({
                      type: "is-danger",
                      message: errorField.toString() || error[0],
                    })
                    clearError()
                  }
                  if (state.modalErrors) {
                    actions.show({ type: "is-danger", message: state.modalErrors })
                    setState(prevState => ({
                      ...prevState,
                      modalErrors: undefined,
                    }))
                  }
                  if (
                    !itinerary.payload &&
                    trip.flight_seat_info &&
                    !trip.flight_seat_info.ticket_id
                  ) {
                    actions.show({ type: "is-warning", message: error })
                    return <Redirect to="/" />
                  }

                  if (cart.checked_out_status) {
                    history.push("/payments/response")
                  }

                  const door2doorLegs = [["Depart leg", "Depart leg"]]
                  if (returnTicket) {
                    door2doorLegs.push(["Return leg", "Return leg"])
                  }

                  return (
                    <>
                      <Breadcrumbs history={history} className="payment-page-breadcrumbs" />
                      <MobileBreadcrumbs
                        history={history}
                        className="payment-page-mobile-breadcrumbs"
                        forceActiveIndex={showPaymentCapture ? 4 : false}
                      />

                      <SectionWrapper>
                        <PaymentWrapper>
                          <div
                            className="payment-summary-container"
                            style={{
                              display: showPaymentCapture ? "none" : "block",
                            }}
                          >
                            <TripSummary
                              oneWay={bookingSearchParams.one_way}
                              departureTicket={departureTicket}
                              returnTicket={returnTicket}
                              departureTicketTotalTripTime={departureTicketTotalTripTime}
                              returnTicketTotalTripTime={returnTicketTotalTripTime}
                              departureTicketLayoverTimes={departureTicketLayoverTimes}
                              returnTicketLayoverTimes={returnTicketLayoverTimes}
                              headline={false}
                              pnr={cart.reservation?.rloc}
                              showPnr
                            />

                            {instruction.door2DoorService.map(city => (
                              <Door2DoorContainer key={city.CityCode}>
                                <Door2DoorInfo>
                                  <ReactMarkdown source={city.Door2DoorInstructions} />
                                </Door2DoorInfo>
                              </Door2DoorContainer>
                            ))}
                            <div className="inline-payment-info">
                              <PaymentPrices
                                isLoyaltyActive={isLoyaltyActive}
                                hideVoucher={isLoyaltyActive}
                                items={cart.items_list}
                                summary={cart.summary}
                                summaryLp={cart.summary_lp}
                                loyaltyBalance={walletBalance}
                                voucher={voucherItem}
                                voucherCode={state.voucherCode}
                                onPromoCodeChanged={handlePromoCodeChange}
                                onAddPromoCodeClicked={onAddPromoCodeClicked}
                                onRemovePromoCodeClicked={onRemovePromoCodeClicked}
                                paymentNeeded={cart.is_payment_necessary}
                                previousTicket={
                                  isEmptyObject(cart.prev_cart) ? undefined : cart.prev_cart
                                }
                                preview
                                setRedirectURL={setRedirectURL}
                                editTrip={editTrip}
                                count={cart.count}
                                oneWay={bookingSearchParams.one_way}
                              />

                              {!!cart.total_reward_points && true && (
                                <LoyaltyReward
                                  title="MileMarkers Miles"
                                  reward={cart.total_reward_points}
                                  loyaltyBalance={cart.user_wallet_points || 0}
                                />
                              )}
                            </div>

                            <ConnectionGarantie
                              cartItems={cart.items_list}
                              showNotification={actions.show}
                              connectionGarantieValidation={connectionGarantieValidation}
                              formErrors={state.formErrors}
                            />

                            <Door2Door
                              legs={door2doorLegs}
                              cartItems={cart.items_list}
                              showNotification={actions.show}
                              door2DoorValidation={door2DoorValidation}
                              formErrors={state.formErrors}
                              passengers={passenger}
                            />

                            <button
                              className="confirm-button"
                              onClick={() => {
                                setShowPaymentCapture(true)
                              }}
                            >
                              Confirm
                            </button>
                          </div>
                          <div
                            className="payment-capture-container"
                            style={{
                              display: showPaymentCapture ? "block" : undefined,
                            }}
                          >
                            <h2 className="mobile-payment-capture-header">Payment Information</h2>
                            <CreditCardFormContainer
                              className="credit-card-form-container"
                              style={{
                                width:
                                  !!cart.total_reward_points && isAuthenticated
                                    ? "calc(66% - 34px)"
                                    : "100%",
                              }}
                            >
                              {settings.STRIPE_PUBLISHABLE_KEY && (
                                <StripeProvider apiKey={settings.STRIPE_PUBLISHABLE_KEY}>
                                  <Elements>
                                    <CreditCardForm
                                      pricePoints={cart.summary_lp}
                                      showPayButton={showPayButton}
                                      setShowPayButton={setShowPayButton}
                                      paymentNeeded={cart.is_payment_necessary}
                                      isLoyaltyActive={isLoyaltyActive}
                                      holderName={state.cardHolderName}
                                      formErrors={state.formErrors}
                                      onHolderNameChanged={cardHolderName =>
                                        changeState({ cardHolderName })
                                      }
                                      onSubmit={onCreditCardSubmitted}
                                      onError={e => {
                                        if (e.error) {
                                          changeState({ stripeErrors: e.error.message })
                                        }
                                      }}
                                    />
                                  </Elements>
                                </StripeProvider>
                              )}
                            </CreditCardFormContainer>
                          </div>
                        </PaymentWrapper>
                      </SectionWrapper>
                    </>
                  )
                }}
              />
            </ErrorBoundary>
          )
        }}
      </NotificationConsumer>
    </>
  )
}

Payment.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
}
Payment.defaultProps = {}

export default withRouter(Payment)

const PaymentWrapper = styled.div`
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-size: 20px;
  padding: 0px 20px;
  background-color: #fff;
`

const SectionWrapper = styled.div`
  width: 100%;
  @media screen and (min-width: 1200px) {
    width: 1100px;
    margin: 0 auto;
    padding: 0px;
  }
`

const Door2DoorContainer = styled.div`
  width: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
`

const Door2DoorInfo = styled.div`
  text-align: center;
  width: 80%;
  border: 1px solid #3c3835;
  border-radius: 6px;
  padding: 4px 30px;
  align-items: center;
`

const CreditCardFormContainer = styled.div`
  padding: 20px 0px;
`
