const FortCollinsData = {
    to: {
        title: "From Fort Collins",
        stops: [
            {
                type: "Depart",
                name: "CSU"
            },
            {
                type: "Depart",
                name: "MUL"
            },
            {
                type: "Depart",
                name: "HTC"
            },
            {
                type: "Depart",
                name: "FNL"
            },
            {
                type: "Arrive",
                name: "DEN"
            }
        ],
        itinerary: [
            ["2:05 AM", "2:20 AM", "2:35 AM", "3:00 AM", "4:00 AM"],
            ["4:05 AM", "4:20 AM", "4:35 AM", "5:00 AM", "6:00 AM"],
            ["6:05 AM", "6:20 AM", "6:35 AM", "7:00 AM", "8:05 AM"],
            ["7:05 AM", "7:20 AM", "7:35 AM", "8:00 AM", "9:05 AM"],
            ["8:05 AM", "8:20 AM", "8:35 AM", "9:00 AM", "10:00 AM"],
            ["10:00 AM", "10:15 AM", "10:30 AM", "11:00 AM", "12:00 PM"],
            ["12:00 AM", "12:15 PM", "12:30 PM", "1:10 PM", "2:10 PM"],
            ["13:05 PM", "1:20 PM", "1:35 PM", "2:00 PM", "3:05 PM"],
            ["", "2:20 PM", "2:35 PM", "3:00 PM", "4:05 PM"],
            ["", "3:40 PM", "3:55 PM", "4:25 PM", "5:30 PM"]
        ]
    },
    from: {
        title: "To Fort Collins",
        stops: [
            {
                type: "Depart",
                name: "DEN"
            },
            {
                type: "Arrive",
                name: "FNL"
            },
            {
                type: "Arrive",
                name: "HTC"
            },
            {
                type: "Arrive",
                name: "MUL"
            },
            {
                type: "Arrive",
                name: "CSU"
            }
        ],
        itinerary: [
            ["9:25 AM", "10:30 AM", "11:05 AM", "11:20 AM", "11:35 AM"],
            ["10:35 AM", "11:40 AM", "12:00 PM", "12:15 PM", "12:30 PM"],
            ["11:35 AM", "12:40 PM", "1:10 PM", "1:25 PM", ""],
            ["1:45 PM", "2:50 PM", "3:10 PM", "3:25 PM", ""],
            ["3:00 PM", "4:05 PM", "4:30 PM", "4:45 PM", "5:00 PM"],
            ["4:00 PM", "5:05 PM", "5:25 PM", "5:40 PM", "5:55 PM"],
            ["5:50 PM", "6:55 PM", "7:15 PM", "7:30 PM", "7:45 PM"],
            ["7:20 PM", "8:25 PM", "8:45 PM", "9:00 PM", "9:15 PM"],
            ["8:30 PM", "9:35 PM", "9:55 PM", "10:10 PM", "10:25 PM"]
        ]
    }
}
export default FortCollinsData