import "./LoyaltyProgram.scss"

import { Button, Icon, InputPreview, PageTitle, Price } from "ibe-components"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import featureFlags from "../../../constants/feature-flags"
import { BUY_MILES } from "../../../constants/routes"
import loyaltyActions from "../../../redux/loyalty/actions"

const LoyaltyProgram = ({ balance, loyaltyNumber }) => {
  const dispatch = useDispatch()
  const activateLoyalty = useCallback(
    isActive => dispatch(loyaltyActions.activateLoyalty(isActive)),
    [dispatch],
  )

  const history = useHistory()

  if (!featureFlags().loyaltyFeatureFlag) return null

  const goToBuyPoints = () => {
    activateLoyalty(true)
    history.push(BUY_MILES)
  }

  return (
    <div className="loyalty-program-wrapper">
      <PageTitle
        title="MileMarkers&#8480; Program"
        fontSize="20px"
        margin="0 0 20px 0"
        style={{
          height: 40,
          boxSizing: "content-box",
          display: "flex",
          alignItems: "center",
        }}
      />
      <InputPreview
        title={<span>YOUR MILEMARKERS&#8480; NUMBER</span>}
        value={`#${loyaltyNumber}`}
      />
      <InputPreview title="YOUR MILES" value={balance} />
      <div className="loyalty-program-buttons">
        <Button
          type="secondary"
          onClick={goToBuyPoints}
          fullWidth
          icon={<Icon align="right" name="points" />}
        >
          Buy Miles
        </Button>
      </div>
    </div>
  )
}

LoyaltyProgram.propTypes = {
  balance: PropTypes.number.isRequired,
  loyaltyNumber: PropTypes.string.isRequired,
}

LoyaltyProgram.defaultProps = {}

export default LoyaltyProgram
