import "./ReservationDepartPage.scss"
import "react-virtualized/styles.css"

import {
  EmptyState,
  Icon,
  ItinerarySingleRow,
  Modal,
  NotificationConsumer,
  ReservationHeader,
  SearchFilters,
} from "ibe-components"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, withRouter } from "react-router-dom"
import { AutoSizer, CellMeasurer, CellMeasurerCache, List, WindowScroller } from "react-virtualized"

import featureFlags from "../../../constants/feature-flags"
import { DEPARTURE_TICKET } from "../../../constants/ticket"
import errorActions from "../../../redux/error/actions"
import reservationActions from "../../../redux/reservations/actions"
import {
  accountSelector,
  errorSelector,
  itinerarySelector,
  loyaltySelector,
  redirectSelector,
  reservationsSelector,
  ticketSelector,
  tripSelector,
} from "../../../redux/selector"
import ticketActions from "../../../redux/ticket/actions"
import tripActions from "../../../redux/trip/actions"
import {
  decreaseDateBy,
  increaseDateBy,
  isDateAfter,
  toDateFormatString,
} from "../../../utils/common"
import { calculateSeatsLeft, getCityName, getSectionType } from "../../../utils/tripUtil-util"
import Breadcrumbs from "../../atoms/Breadcrumbs"
import ErrorBoundary from "../../atoms/ErrorBoundary"
import Loading from "../../atoms/Loading"
import PageLayout from "../../layouts/PageLayout"
import Search from "../HomePage/Search"

const ReservationDepartPage = ({ history }) => {
  const reservations = useSelector(state => reservationsSelector(state))
  const trip = useSelector(state => tripSelector(state))

  const redirection = useSelector(state => redirectSelector(state))

  const bookingTicket = useSelector(state => ticketSelector(state))
  const itinerary = useSelector(state => itinerarySelector(state))
  const { isAuthenticated } = useSelector(state => accountSelector(state))
  const loyalty = useSelector(state => loyaltySelector(state))
  const isLoyaltyActive = loyalty.isActive
  const error = useSelector(state => errorSelector(state))

  const dispatch = useDispatch()

  const setReservationProperty = useCallback(
    field => value => dispatch(reservationActions.setReservationProperty({ field, value })),
    [dispatch],
  )
  const setTicketByType = useCallback(params => dispatch(ticketActions.setTicketByType(params)), [
    dispatch,
  ])
  const setTicketIndexByType = useCallback(
    params => dispatch(ticketActions.setTicketIndexByType(params)),
    [dispatch],
  )

  const updateReservation = useCallback(() => dispatch(reservationActions.updateReservation()), [
    dispatch,
  ])

  const setFilterProperty = useCallback(
    filter => dispatch(reservationActions.setFilterProperty(filter)),
    [dispatch],
  )

  const clearFilters = useCallback(() => dispatch(reservationActions.clearFilters()), [dispatch])

  const clearFlightDetails = useCallback(() => dispatch(tripActions.clearFlightDetails()), [
    dispatch,
  ])

  const clearError = useCallback(() => dispatch(errorActions.clearError()), [dispatch])
  const getFlightDetails = useCallback(
    flightRequestData => dispatch(tripActions.getFlightDetails(flightRequestData)),
    [dispatch],
  )

  const departFromRef = useRef(null)
  const params = reservations.params || {}
  const [state, setState] = useState({
    shouldShowTripSummaryModal: false,
    currentDepartureItineraryIndex: -1,
    currentReturnItineraryIndex: -1,
    error: undefined,
    redirectToNext: false,
    mustLogin: false,
    filters: { ...reservations.filters },
    showModalFilters: false,
  })

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        ...reservations.filters,
      },
    }))
  }, [reservations.filters])

  const setDepartureTicketIndex = index =>
    setTicketIndexByType({
      type: DEPARTURE_TICKET,
      index,
    })
  const setDepartureTicket = ({
    segments,
    segmentIndex,
    ticket,
    ticketIndex,
    summaryPrice,
    layoverTimes,
    totalTripTime,
    returnFlights,
    bookingToken,
  }) => {
    setTicketByType({
      type: DEPARTURE_TICKET,
      segments,
      segmentIndex,
      ticket,
      ticketIndex,
      layoverTimes,
      summaryPrice,
      totalTripTime,
      returnFlights,
      bookingToken,
    })
  }

  useEffect(() => {
    clearFlightDetails()
    if (isLoyaltyActive && !isAuthenticated) {
      setState(prevState => ({
        ...prevState,
        mustLogin: true,
      }))
    }
  }, [])

  const setDepartureDate = date => {
    setReservationProperty("departure_date")(date)
    setDepartureTicket({
      segments: undefined,
      segmentIndex: -1,
      ticket: undefined,
      ticketIndex: -1,
      summaryPrice: undefined,
      layoverTimes: undefined,
      totalTripTime: undefined,
      bookingToken: undefined,
      returnFlights: undefined,
    })
  }
  const onDecreaseDepartureDateClick = () => {
    if (isDateAfter(params.departure_date, new Date())) {
      const date = decreaseDateBy(params.departure_date, 1)
      setDepartureDate(date)
      updateReservation()
    }
  }
  const onIncreaseDepartureDateClick = () => {
    const date = increaseDateBy(params.departure_date, 1)
    if (params.return_date && isDateAfter(date, params.return_date)) {
      setReservationProperty("return_date")(date)
    }
    setDepartureDate(date)
    updateReservation()
  }

  const onDepartureTicketClick = (
    inheritTicket,
    ticketIndex,
    itineraryIndex,
    segmentIndex,
    segments,
    summaryPrice,
    layoverTimes,
    totalTripTime,
    returnFlights,
    bookingToken,
  ) => {
    setDepartureTicketIndex(ticketIndex)
    setState(prevState => ({
      ...prevState,
      currentDepartureItineraryIndex: itineraryIndex,
    }))
    setDepartureTicket({
      segments,
      segmentIndex,
      ticket: inheritTicket,
      ticketIndex,
      summaryPrice,
      layoverTimes,
      totalTripTime,
      returnFlights,
      bookingToken,
    })
    if (redirection.url === "/reservations/depart") {
      history.push("/reservations/summary")
    }
    setState(prevState => ({
      ...prevState,
      redirectToNext: true,
    }))
  }

  const onCabinTypeChange = e => {
    const cabin = e.target.value
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        cabins_class: cabin,
      },
    }))
  }
  const onAirlineFilterChange = (e, index) => {
    const { checked } = e.target

    const newAirlines = [...state.filters.airline_filter] // copy the state => anti shadowing

    newAirlines[index] = { ...newAirlines[index], checked } // modify single index instance of airline_filter

    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        airline_filter: newAirlines,
      },
    }))
  }

  const onApplyFilters = () => {
    setState(prevState => ({ ...prevState, showModalFilters: false }))
    setFilterProperty(state.filters)
  }

  const onResetFilters = () => {
    setState(prevState => ({ ...prevState, showModalFilters: false }))
    setState(prevState => ({ ...prevState, filters: { ...reservations.filters } }))
    clearFilters()
  }
  const onBusLayoverChange = value => {
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        maxBusLayover: value,
      },
    }))
  }
  const onFlightLayoverTimeChange = value => {
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        maxFlightLayover: value,
      },
    }))
  }
  const onTotalTripTimeChange = value => {
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        total_trip_time_max: value,
      },
    }))
  }
  const onMaxStopoversChange = e => {
    const maxStops = Number(e.target.value) - 1 // RadioGrup trash component, needs refactor
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        max_stopovers: maxStops,
      },
    }))
  }

  const showFlightFilters =
    featureFlags().showFlightSearchFeatureFlag && reservations.items.is_interlined

  const onConnectingAirportsChange = (e, index) => {
    const { checked } = e.target

    const connectingAirports = [...state.filters.connecting_airports] // copy the state => anti shadowing

    connectingAirports[index] = { ...connectingAirports[index], checked } // modify single index instance of airline_filter

    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        connecting_airports: connectingAirports,
      },
    }))
  }
  const { currentDepartureItineraryIndex } = state
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 229,
  })

  const listRef = useRef()

  const [collapsed, setCollapsed] = useState({})
  useEffect(() => {
    if (listRef.current) {
      listRef.current.recomputeRowHeights()
    }
    cache.clearAll()
  }, [collapsed])

  if (!params.dep_from) {
    return <Redirect to="/" />
  }
  const rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style,
    parent,
  }) => {
    const data = reservations.items.depart_flights[index]
    const {
      segments,
      trip_summary: summaryPrice,
      layover_times: layoverTimes,
      total_trip_time: totalTripTime,
      return_flights: returnFlights,
      booking_token: bookingToken,
    } = data
    const { tickets } = segments.find(segment => segment.operator === "KIWI") || {}
    const seatsLeft = calculateSeatsLeft(summaryPrice)

    const realReturnFlight = returnFlights || []
    let additionalCharge

    realReturnFlight.forEach(returnFlight => {
      returnFlight.trip_summary.tickets.forEach(returnTicket => {
        let price
        if (isLoyaltyActive) {
          price = returnTicket.price_in_miles
        } else {
          price = returnTicket.landline_price + (returnTicket.flight_price || 0)
        }
        if (!additionalCharge || price < additionalCharge) {
          additionalCharge = price
        }
      })
    })

    return (
      <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
        <div style={style}>
          <ItinerarySingleRow
            excludeFlight={!params.one_way}
            oneWay={params.one_way}
            reservationDepart
            startsAt={additionalCharge}
            isCollapsableOpen={collapsed[index]}
            useLoyalty={isLoyaltyActive}
            itineraryIndex={index}
            selectedItineraryIndex={currentDepartureItineraryIndex}
            selectedTicketIndex={bookingTicket.departureTicketIndex}
            seatsLeft={seatsLeft}
            totalTripTime={totalTripTime}
            passengersAdults={params.passengers_adults}
            passengersChildrens={params.passengers_children}
            passengersInfants={params.passengers_infants}
            onTicketClicked={onDepartureTicketClick}
            returnFlights={returnFlights}
            onCollapse={newColap => {
              setCollapsed(prevState => ({ ...prevState, ...newColap }))
            }}
            segments={segments}
            bookingToken={bookingToken}
            layoverTimes={layoverTimes}
            departureCode={data.departure_code}
            arrivalCode={data.arrival_code}
            priceCeil={isLoyaltyActive}
            currencySymbol={isLoyaltyActive ? "PTS" : "USD"}
            segmentIndex={0}
            tickets={tickets}
            summaryPrice={summaryPrice.tickets}
            classbands={reservations.items.classbands}
            flightSearchActive={featureFlags().showFlightSearchFeatureFlag}
            getFlightDetails={getFlightDetails}
            luggage={trip.flightDetails[index]}
            loading={trip.flightDetails.isBusy}
          />
        </div>
      </CellMeasurer>
    )
  }
  rowRenderer.propTypes = {
    parent: PropTypes.instanceOf(Object).isRequired,
    key: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    style: PropTypes.instanceOf(Object).isRequired,
  }

  return (
    <ErrorBoundary>
      <NotificationConsumer>
        {({ actions }) => {
          const isPrevDisabled = () => {
            const currentDate = moment(params.departure_date).format("MM DD YYYY")
            const minDate = params.fromMinDate
              ? moment(params.fromMinDate).format("MM DD YYYY")
              : moment().format("MM DD YYYY")

            return moment(currentDate).isSame(minDate)
          }

          const isNextDisabled = () => {
            if (params.fromMaxDate) {
              const currentDate = moment(params.departure_date).format("MM DD YYYY")
              const maxDate = moment(params.fromMaxDate).format("MM DD YYYY")

              return moment(currentDate).isSame(maxDate)
            }

            return false
          }

          return (
            <PageLayout
              render={() => {
                if (
                  reservations.isBusy ||
                  itinerary.isBusy ||
                  bookingTicket.isBusy ||
                  (error && error.isBusy)
                ) {
                  return <Loading />
                }
                if (state.error) {
                  actions.show({ type: "is-danger", message: state.error })
                  setState(prevState => ({ ...prevState, error: undefined }))
                }
                if (error) {
                  actions.show({ type: "is-danger", message: error })
                  clearError()
                }
                if (state.redirectToNext) {
                  actions.show({
                    type: "is-success",
                    message: `You have successfully selected departure ticket.`,
                  })
                  if (params.one_way) {
                    history.push("/reservations/summary")
                  } else {
                    history.push("/reservations/return")
                  }
                }
                if (state.mustLogin) {
                  actions.show({
                    type: "is-danger",
                    message: "Please log in to your account to use MileMarkers",
                  })
                  setState(prevState => ({ ...prevState, mustLogin: false }))
                }
                return (
                  <div className="reservation-depart-page-container">
                    <Breadcrumbs
                      history={history}
                      className="reservation-depart-page-breadcrumbs"
                    />

                    <div className="SectionWrapper reservation-section-wrapper">
                      <div className="reservation-depart-wrapper">
                        <div className="reservation-container">
                          <div ref={departFromRef}>
                            <ReservationHeader
                              isEditTripActive={trip.editTrip !== ""}
                              searchPadding={getSectionType()}
                              from={params.dep_from}
                              to={params.dep_to}
                              date={toDateFormatString(params.departure_date, "dddd, MMM DD, YYYY")}
                              onPrevClicked={onDecreaseDepartureDateClick}
                              onNextClicked={onIncreaseDepartureDateClick}
                              search={<Search history={history} remoteCallSearchComponent />}
                              getCityName={getCityName}
                              getMobileCityName={n => n.code}
                              prevDisabled={isPrevDisabled()}
                              nextDisabled={isNextDisabled()}
                            />
                            {showFlightFilters && (
                              <div className="filters-mobile-container">
                                <div
                                  className="filters-mobile-wrapper"
                                  onClick={() => {
                                    setState(prevState => ({
                                      ...prevState,
                                      showModalFilters: true,
                                    }))
                                  }}
                                >
                                  <Icon
                                    name="filterIcon"
                                    noAbsolute
                                    withoutProps
                                    width="21"
                                    height="18"
                                  />
                                  <span className="text">Filter Results</span>
                                </div>
                              </div>
                            )}
                            <Modal
                              showFooterButtons={false}
                              isOpen={state.showModalFilters}
                              onCancel={() => {
                                setState(prevState => ({ ...prevState, showModalFilters: false }))
                              }}
                              onSuccess={() => {
                                setState(prevState => ({ ...prevState, showModalFilters: false }))
                              }}
                              title="Filter Results"
                              render={() => {
                                return (
                                  <>
                                    <SearchFilters
                                      cabinTypes={state.filters.cabins_class}
                                      onCabinTypeChange={onCabinTypeChange}
                                      maxBusLayover={state.filters.maxBusLayover}
                                      maxFlightLayover={state.filters.maxFlightLayover}
                                      onBusLayoverChange={onBusLayoverChange}
                                      onFlightLayoverTimeChange={onFlightLayoverTimeChange}
                                      airlineFilter={state.filters.airline_filter}
                                      onAirlineFilterChange={onAirlineFilterChange}
                                      totalTripTime={state.filters.total_trip_time_max}
                                      onTotalTripTimeChange={onTotalTripTimeChange}
                                      onApplyFilters={onApplyFilters}
                                      onResetFilters={onResetFilters}
                                      onMaxStopoversChange={onMaxStopoversChange}
                                      connectingAirports={state.filters.connecting_airports}
                                      onConnectingAirportsChange={onConnectingAirportsChange}
                                      modal
                                    />
                                  </>
                                )
                              }}
                            />
                            <div className="reservation-body">
                              <h2 className="departure-date">
                                Departure: {moment(params.departure_date).format("MM/DD/YYYY")}
                              </h2>
                              <div className="itinerary-list-container">
                                {reservations.items.depart_flights.length > 0 ? (
                                  <WindowScroller>
                                    {({ height, isScrolling, onChildScroll, scrollTop }) => {
                                      return (
                                        <AutoSizer disableHeight>
                                          {({ width }) => {
                                            return (
                                              <List
                                                isScrolling={isScrolling}
                                                onScroll={onChildScroll}
                                                scrollTop={scrollTop}
                                                autoHeight
                                                ref={listRef}
                                                deferredMeasurementCache={cache}
                                                height={height}
                                                rowRenderer={rowRenderer}
                                                rowCount={reservations.items.depart_flights.length}
                                                width={width}
                                                rowHeight={cache.rowHeight}
                                              />
                                            )
                                          }}
                                        </AutoSizer>
                                      )
                                    }}
                                  </WindowScroller>
                                ) : (
                                  <EmptyState />
                                )}
                              </div>
                              {showFlightFilters && (
                                <div className="filters-container">
                                  <SearchFilters
                                    cabinTypes={state.filters.cabins_class}
                                    maxBusLayover={state.filters.maxBusLayover}
                                    maxFlightLayover={state.filters.maxFlightLayover}
                                    onBusLayoverChange={onBusLayoverChange}
                                    onFlightLayoverTimeChange={onFlightLayoverTimeChange}
                                    onCabinTypeChange={onCabinTypeChange}
                                    maxStopovers={state.filters.max_stopovers}
                                    maxLayover={state.filters.max_layover}
                                    airlineFilter={state.filters.airline_filter}
                                    totalTripTime={state.filters.total_trip_time_max}
                                    onTotalTripTimeChange={onTotalTripTimeChange}
                                    onAirlineFilterChange={onAirlineFilterChange}
                                    onApplyFilters={onApplyFilters}
                                    onResetFilters={onResetFilters}
                                    onMaxStopoversChange={onMaxStopoversChange}
                                    connectingAirports={state.filters.connecting_airports}
                                    onConnectingAirportsChange={onConnectingAirportsChange}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }}
            />
          )
        }}
      </NotificationConsumer>
    </ErrorBoundary>
  )
}

ReservationDepartPage.propTypes = {
  history: PropTypes.instanceOf(Object),
}
ReservationDepartPage.defaultProps = {
  history: {},
}

export default withRouter(ReservationDepartPage)
