export const HOME = "/"
export const PROFILE = "/profile"
export const MILE_ACCRUAL_LOG = "/miles"
export const BUY_MILES = "/miles/buy"
export const MILES_CONFIRM = "/miles/confirmation"
export const REFERRAL = "/referral"
export const TRIPS = "/trips"
export const MANAGE_TRIPS = "/trips/manage"
export const PAYMENT = "/payments"

export const TERMS_AND_CONDITIONS = "/terms-and-conditions"
export const PRIVACY_POLICY = "/privacy-policy"
export const LANDLINE_COVID19 = "/landlinecovid19response"
export const NEWS_PAGE = "/news"
export const THE_EXPERIENCE_PAGE = "/the-experience"
export const FAQ_PAGE = "/faq"
export const CAREERS_PAGE = "/careers"
export const CONTACT_PAGE = "/contact"
export const MINNESOTA_PAGE = "/minnesota"
export const MILEMARKERS_PAGE = "/milemarkers"
export const TSA_RESERVE = "/landline/reserve"
export const ABOUT_US_PAGE = "/about-us"
export const SCHEDULE_AND_FARES = "/landline/locations"
export const HOW_IT_WORKS = "/how-it-works"
export const CHARTER_LANDLINE = "/landline/charters"
export const BLACKCAR_LANDLINE = "/landline-black-car-service"

export const PASSWORD_RECOVERY = "/password/recovery"
export const PASSWORD_RESET = "/password/reset"
export const LOGIN = "/login"
export const ACCOUNT_REGISTER = "/account/register"

export const EXTERNAL_WEBSITE = 0
export const THIS_WEBSITE = 1

export const DEPART_SEATS = "/seats/depart"
export const RETURN_SEATS = "/seats/return"
export const DEPART_PICKUP = "/pickupDetails/depart"
export const RETURN_PICKUP = "/pickupDetails/return"
