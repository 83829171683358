const Pickup_Locations = [
  {
    image: "https://content.landline.com/wp-content/uploads/2024/04/CSU-Map.jpg",
    title: "CSU Transit Center (CSU)",
    prices: "Adults: $29 | Kids: FREE (12 & under)",
    link: "https://maps.google.com/maps?ll=40.576033,-105.085223&z=16&t=m&hl=en&gl=US&mapclient=embed&cid=12154003753580978015",
    top: "Pick up/drop off is on the north side of Plum Ave just east of the service drive for Allison Hall.",
    bottom: "There is no public parking available on the CSU campus, however, it is steps away from public transportation and is easily accessible via S Meridian St. or W Plumb St. for family/friend drop off/pick up."
  },
  {
    image: "https://content.landline.com/wp-content/uploads/2024/04/HTC-Map.jpg",
    title: "Harmony Transfer Center (HTC)",
    prices: "Adults: $29 | Kids: FREE (12 & under)",
    link: "https://maps.google.com/maps?ll=40.524117,-104.996167&z=14&t=m&hl=en&gl=US&mapclient=embed&cid=14298134024812418491",
    top: "Pick up/drop off is on the northeast side of the HTC parking lot (see photo for reference). We recommend family/friend drop off.",
    bottom: "This parking lot is short term (24hr) only and monitored by Fort Collins parking enforcement."
  },
  {
    image: "https://content.landline.com/wp-content/uploads/2024/05/Mulberry_stop.jpg",
    title: "Mulberry & I-25 (MUL) - FREE Parking",
    prices: "Adults: $29 | Kids: FREE (12 & under)",
    link: "https://www.google.com/maps/place/40%C2%B034'49.4%22N+105%C2%B000'20.5%22W/@40.5803889,-105.0056944,413m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d40.5803889!4d-105.0056944?entry=ttu",
    top: "The landline free parking lot is located at 3825 E Mulberry Street, Fort Collins, CO 80524.",
    bottom: "Shuttle pick-up and drop off occurs on Stockton Ave adjacent to the parking lot."
  },
  {
    image: "https://content.landline.com/wp-content/uploads/2024/05/FNL_stop.jpg",
    title: "Northern Colorado Regional Airport (FNL) - FREE Parking",
    prices: "Adults: $35 | Kids: FREE (12 & under)",
    link: "https://maps.google.com/maps?ll=40.449842,-105.005387&z=16&t=m&hl=en&gl=US&mapclient=embed&cid=2460148492228003283",
    top: "Check in is located inside the main entrance of the terminal at the Landline/United desk. Parking at FNL is FREE for up to three weeks for all Landline passengers.",
    bottom: "For trips longer than three weeks, please contact the FNL Airport admin at 970-962-2862 to purchase an extended parking pass."
  },
]

export default Pickup_Locations