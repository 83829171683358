import React from "react"
import SchedulePage from ".."
import ScheduleHeader from "../Header"
import OurService from "../OurService"
import ModuleTitle from "../../../atoms/SchedulePages/ModuleTitle"
import { ScheduleLocation } from "../Locations"
import { Schedule, ScheduleTable } from "../Schedule"
import Search from "../../HomePage/Search"

import { FortCollinsLocation } from "./locations"
import FortCollinsData from "../FortCollins/schedule"
import { DenverLocationService, DenverMeta, DenverService } from "./metadata"
import LocationService from "../Service"



const DenverSchedule = ({ history }) => {

    const goToFortCollins = () => {
        history.push('/fort-collins')
    }

  return (
    <SchedulePage>
      <div className="schedule-search-form">
        <Search history={history} remoteCallSearchComponent={true} />
      </div>
      <div className="schedule-page">
        <ScheduleHeader metadata={DenverMeta.header} />

        <ModuleTitle title="Fort Collins / Loveland" />
        <ScheduleLocation data={FortCollinsLocation} onButtonClick={goToFortCollins} />
        <Schedule>
          <ScheduleTable schedule={FortCollinsData.from} />
          <ScheduleTable schedule={FortCollinsData.to} />
        </Schedule>

        <OurService service={DenverService} />

        <LocationService service={DenverLocationService} />

      </div>
    </SchedulePage>
  )
}

export default DenverSchedule