import "./TopMenuHamburger.scss"

import { Icon } from "ibe-components"
// import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import {
  FAQ_PAGE,
  HOW_IT_WORKS,
  MILEMARKERS_PAGE,
  SCHEDULE_AND_FARES,
  CHARTER_LANDLINE,
  TSA_RESERVE
} from "../../../constants/routes"
import config from "../../../utils/config"
import NavLink from "../../atoms/NavLink"
import TopMenuLogo from "../../atoms/TopMenuLogo"

const TopMenuHamburger = () => {
  const [isOpen, setOpenMenu] = useState(false)

  const toogleMenu = () => {
    setOpenMenu(!isOpen)
  }

  useEffect(() => {
    if (isOpen) {
      document.querySelector("body").classList.add("overflowHidden")
    } else {
      document.querySelector("body").classList.remove("overflowHidden")
    }
  }, [isOpen])

  return (
    <div className="top-menu-hamburger-wrapper">
      <div className="top-menu-hamburger-icon">
        <Icon
          noAbsolute
          onClick={toogleMenu}
          className="top-menu-icons"
          name="hamburger"
          fill="#FAB500"
          width="20"
          height="14"
          viewBox="0 0 20 14"
        />
      </div>
      {isOpen && (
        <div className="hamburger-links">
          <div className="logo-wrapper">
            <TopMenuLogo />
          </div>
          <div className="top-menu-hamburger-link">
            <div className="Separator--menu" />
            <NavLink to={`${config.LANDLINE_URL}${HOW_IT_WORKS}`}>How It Works</NavLink>
            <div className="Separator--menu" />
            <NavLink to={`${config.LANDLINE_URL}${SCHEDULE_AND_FARES}`}>Schedule & Fares</NavLink>
            <div className="Separator--menu" />
            <NavLink to={`${config.LANDLINE_URL}${FAQ_PAGE}`}>FAQ</NavLink>
            <div className="Separator--menu" />
			 <NavLink to={`${config.LANDLINE_URL}${CHARTER_LANDLINE}`}>Charters</NavLink>
            <div className="Separator--menu" />
            <NavLink to={`${config.LANDLINE_URL}${MILEMARKERS_PAGE}`}>MileMarkers</NavLink>
            <div className="Separator--menu" />
            <NavLink to={`${config.LANDLINE_URL}${TSA_RESERVE}`}>TSA Reserve</NavLink>
            <div className="Separator--menu" />
          </div>

          <div className="close-button" onClick={toogleMenu}>
            <Icon
              name="closeIcon"
              width="20"
              height="20"
              fill="#FAB500"
              noAbsolute
              viewBox="0 0 20 20"
            />
          </div>
        </div>
      )}
    </div>
  )
}

TopMenuHamburger.propTypes = {
  // onToggle: PropTypes.func,
}

TopMenuHamburger.defaultProps = {
  onToggle: () => null,
}

export default TopMenuHamburger
