import { NotificationProvider, Zendesk } from "ibe-components"
import React, { useCallback, useEffect } from "react"
import { useDispatch } from "react-redux"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { ApolloProvider } from "@apollo/client"

import { BUY_MILES, MILE_ACCRUAL_LOG, MILES_CONFIRM } from "../constants/routes"
import settingsActions from "../redux/settings/actions"
import { history } from "../redux/store"
import config from "../utils/config"
import { init } from "../utils/sentry"
import Authentication from "./atoms/Authentication"
import ScrollToTop from "./atoms/ScrollToTop/ScrollToTop"
import Activate from "./pages/ActivePage"
import CancelTrip from "./pages/CancelTrip"
import ChangeSeatPage from "./pages/ChangeSeatPage"
import CheckInPage from "./pages/CheckInPage"
import GA from "./pages/GoogleAnalyticsPage"
import GoogleTag from "./pages/GoogleTagPage"
import Home from "./pages/HomePage"
import Login from "./pages/LoginPage"
import Logout from "./pages/LogoutPage"
import LoyaltyBuyPointsConfirmationPage from "./pages/LoyaltyBuyPointsConfirmationPage"
import LoyaltyBuyPointsPage from "./pages/LoyaltyBuyPointsPage"
import ManageTripsPage from "./pages/ManageTripsPage"
import MileMarkerAccrualLogPage from "./pages/MileMarkerAccrualLogPage"
import MyTripsPage from "./pages/MyTripsPage"
import Passenger from "./pages/PassengerPage"
import PasswordAssistance from "./pages/PasswordAssistancePage"
import PasswordEdit from "./pages/PasswordEditPage"
import PasswordReset from "./pages/PasswordResetPage"
import Payment from "./pages/PaymentPage"
import PaymentResponse from "./pages/PaymentResponsePage"
import PickupDetailsPage from "./pages/PickupDetailsPage"
import ProfilePage from "./pages/ProfilePage"
import ReferalPage from "./pages/ReferalPage"
import ReservationDepartPage from "./pages/ReservationDepartPage"
import ReservationReturnPage from "./pages/ReservationReturnPage"
import ReservationSummaryPage from "./pages/ReservationSummaryPage"
import Seat from "./pages/SeatPage"
import SignUp from "./pages/SignUpPage"
import TripDetailsPage from "./pages/TripDetailsPage"
import { client } from "../utils/gql"
import ActiveCampaign from "./atoms/ActiveCampaign"
import FortCollinsSchedule from "./pages/SchedulePages/FortCollins"
import DenverSchedule from "./pages/SchedulePages/Denver"
import FargoSchedule from "./pages/SchedulePages/Fargo"
import DuluthSchedule from "./pages/SchedulePages/Duluth"
import MSPSchedule from "./pages/SchedulePages/MSP"
import PickupLocationsPage from "./pages/PickupLocationsPage"
import ReservePage from "./pages/SchedulePages/Reserve"
import ChartersPage from "./pages/ChartersPage"

init()
GoogleTag.init()

const RouteWrapperHOC = RouteComponent => props => (
  <>
    <ActiveCampaign />
    <RouteComponent {...props} />
  </>
)

const App = () => {
  const dispatch = useDispatch()
  const getSettingsData = useCallback(() => dispatch(settingsActions.getSettingsData(), [dispatch]))

  useEffect(() => {
    getSettingsData()
  }, [])

  return (
    <>
      <BrowserRouter basename="/landline">
        <ScrollToTop>
          <ApolloProvider client={client}>
            <NotificationProvider>
              <>
                {GA.init() && <GA.RouteTracker />}
                <GoogleTag.Manager />
                <Authentication />
                <ActiveCampaign />
                <Zendesk history={history} principal={config.ZENDESK_KEY} />
                <Switch>
                  <Route path="/login" component={RouteWrapperHOC(Login)} />
                  <Route path="/logout" component={RouteWrapperHOC(Logout)} />
                  <Route exact path="/profile" component={RouteWrapperHOC(ProfilePage)} />
                  <Route
                    exact
                    path="/password/recovery"
                    component={RouteWrapperHOC(PasswordAssistance)}
                  />
                  <Route
                    exact
                    path="/password/reset/:code"
                    component={RouteWrapperHOC(PasswordReset)}
                  />
                  <Route path="/password/manage" component={RouteWrapperHOC(PasswordEdit)} />
                  <Route path="/account/register" component={RouteWrapperHOC(SignUp)} />
                  <Route path="/activate/:code" exact component={RouteWrapperHOC(Activate)} />
                  <Route
                    path="/activate/:code/:referralCode"
                    exact
                    component={RouteWrapperHOC(Activate)}
                  />
                  <Route exact path="/trips/manage" component={RouteWrapperHOC(ManageTripsPage)} />
                  <Route exact path="/trips/details" component={RouteWrapperHOC(TripDetailsPage)} />
                  <Route
                    exact
                    path="/trips/:pnr/:segmentDirection/checkin"
                    component={RouteWrapperHOC(CheckInPage)}
                  />
                  <Route exact path="/trips/:pnr/cancel" component={RouteWrapperHOC(CancelTrip)} />
                  <Route path="/trips" component={RouteWrapperHOC(MyTripsPage)} />
                  <Route exact path="/payments" component={RouteWrapperHOC(Payment)} />
                  <Route
                    exact
                    path="/payments/response"
                    component={RouteWrapperHOC(PaymentResponse)}
                  />

                  <Route
                    path="/reservations/summary"
                    component={RouteWrapperHOC(ReservationSummaryPage)}
                  />
                  <Route
                    path="/reservations/depart"
                    component={RouteWrapperHOC(ReservationDepartPage)}
                  />
                  <Route
                    path="/reservations/return"
                    component={RouteWrapperHOC(ReservationReturnPage)}
                  />

                  <Route
                    path="/pickupDetails/depart"
                    component={RouteWrapperHOC(PickupDetailsPage)}
                  />
                  <Route
                    path="/pickupDetails/return"
                    component={RouteWrapperHOC(PickupDetailsPage)}
                  />

                  <Route path="/passengers" component={RouteWrapperHOC(Passenger)} />
                  <Route path="/seats/change" component={RouteWrapperHOC(ChangeSeatPage)} />
                  <Route path="/seats/depart" component={RouteWrapperHOC(Seat)} />
                  <Route path="/seats/return" component={RouteWrapperHOC(Seat)} />
                  <Route exact path={BUY_MILES} component={RouteWrapperHOC(LoyaltyBuyPointsPage)} />
                  <Route
                    exact
                    path={MILES_CONFIRM}
                    component={RouteWrapperHOC(LoyaltyBuyPointsConfirmationPage)}
                  />
                  <Route
                    exact
                    path={MILE_ACCRUAL_LOG}
                    component={RouteWrapperHOC(MileMarkerAccrualLogPage)}
                  />
                  <Route path="/referral" component={RouteWrapperHOC(ReferalPage)} />
                  <Route path="/charters" component={RouteWrapperHOC(ChartersPage)} />

                  <Route
                    path={[
                      "/fort-collins",
                      "/fort-collins-shuttle",
                      "/fort-collins-airport-shuttle",
                    ]}
                    component={RouteWrapperHOC(FortCollinsSchedule)}
                  />
                  <Route
                    path={["/denver", "/denver-shuttle", "/denver-airport-shuttle"]}
                    component={RouteWrapperHOC(DenverSchedule)}
                  />
                  <Route
                    path={["/fargo", "/fargo-shuttle"]}
                    component={RouteWrapperHOC(FargoSchedule)}
                  />
                  <Route
                    path={["/duluth", "/duluth-shuttle"]}
                    component={RouteWrapperHOC(DuluthSchedule)}
                  />
                  <Route path={["/msp", "/msp-shuttle"]} component={RouteWrapperHOC(MSPSchedule)} />

                  <Route path={["/pickup-locations", "/locations"]} component={RouteWrapperHOC(PickupLocationsPage)} />

                  <Route path={["/reserve"]} component={RouteWrapperHOC(ReservePage)} />


                  <Route path={["/bookings", "/"]} component={RouteWrapperHOC(Home)} />
                </Switch>
              </>
            </NotificationProvider>
          </ApolloProvider>
        </ScrollToTop>
      </BrowserRouter>
    </>
  )
}

export default App
