const FortCollinsLocation = {
    image: "https://content.landline.com/wp-content/uploads/2022/09/cfiles35101-3.jpg",
    title: "",
    prices: "Adults: $29 and up | Kids: FREE (12 & under)",
    content: "Landline offers service to Fort Collins/Loveland from four convenient locations: CSU Transit Center (CSU), Mulberry & I-25 - FREE Parking (MUL), Harmony Transfer Center (HTC), and the Northern Colorado Regional Airport - FREE Parking (FNL).  For more information about specific pick-up locations or parking information, please view our Fort Collins/Loveland page.",
    button: "More about our Fort Collins / Loveland service"
}

export {
    FortCollinsLocation
}