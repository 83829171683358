const Pickup_Locations = [
  {
    image: "https://content.landline.com/wp-content/uploads/2024/05/FAR_Map.jpg",
    title: "Hector International Airport (FAR)",
    prices: "Adults: $39 and up",
    link: "https://www.google.com/maps/place/Hector+International+Airport+Parking/@46.9179647,-96.8280834,17z/data=!3m1!4b1!4m6!3m5!1s0x52c8ca149c6401a1:0x951d1a7f734629bf!8m2!3d46.9179611!4d-96.8255085!16s%2Fg%2F1td1d1r4?entry=ttu",
    top: "Landline picks up in front of the main terminal lobby of the Hector International Airport.",
    bottom: "There is a waiting area inside the terminal for your convenience. The driver will not come inside to announce their arrival. Please be out front and ready for boarding at your scheduled departure time."
  }
]

export default Pickup_Locations