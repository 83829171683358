import "./LoyaltyReward.scss"

import classNames from "classnames"
import { PageTitle, Price } from "ibe-components"
import PropTypes from "prop-types"
import React from "react"

import featureFlags from "../../../constants/feature-flags"

const LoyaltyReward = ({ title, reward, loyaltyBalance }) => {
  const loyaltyRewardClass = classNames("loyalty-reward-wrapper")

  if (!featureFlags().loyaltyFeatureFlag) {
    return null
  }

  return (
    <div className={loyaltyRewardClass}>
      <PageTitle title={title} fontSize="20px" margin="20px 0 0 0" />
      <div className="loyalty-reward-container">
        <div className="loyalty-reward-wrapper-item loyalty-reward-balance">
          <div>Your miles</div>
          <div className="loyalty-reward-wrapper-item-price">
            <Price floor amount={loyaltyBalance} currency="PTS" loyaltyStyle useLoyalty={true} />
          </div>
        </div>
        <div className="loyalty-reward-wrapper-item loyalty-reward-value">
          <div className="wrapper">
            <div>Miles earned this trip</div>
            <div className="loyalty-info">(reflected 24hrs after trip)</div>{" "}
          </div>
          <div className="loyalty-reward-wrapper-item-price">
            <Price floor amount={reward} currency="PTS" loyaltyStyle useLoyalty={true} />
          </div>
        </div>

        <div className="loyalty-reward-wrapper-item loyalty-reward-new-balance">
          <div>Post-trip Mile Balance</div>
          <div className="loyalty-reward-wrapper-item-price">
            <Price floor amount={loyaltyBalance + reward} currency="PTS" loyaltyStyle useLoyalty={true} />
          </div>
        </div>
      </div>
    </div>
  )
}

LoyaltyReward.propTypes = {
  title: PropTypes.string,
  reward: PropTypes.number.isRequired,
  loyaltyBalance: PropTypes.number.isRequired,
}

LoyaltyReward.defaultProps = {
  title: "MileMarkers Miles",
}

export default LoyaltyReward
